import React from 'react';
import Header from './header/header';
import { Footer } from './footer/footer';
import SEO from './seo';
import Helmet from 'react-helmet';

export const Layout = ({ children }) => (
  <div className="site pt-5">
    <SEO />
    <Helmet>
      <script type="text/javascript" src="/js/jquery-3.1.1.min.js" defer={true} />
      <script type="text/javascript" src="/js/mdb.min.js" defer={true} />
    </Helmet>
    <Header />
    <main>
      <div className="container">{children}</div>
    </main>
    <Footer />
  </div>
);
