import React, { useState } from 'react';
import SideNav from './sideNav/sideNav';
import TopNav from './topNav/topNav';

const links = [
  { text: 'Home', url: '' },
  { text: 'About', url: 'about-fitbodiz' },
  { text: 'Personal Trainer', url: 'personal-trainer' },
  { text: 'Gym', url: 'gym-facilities' },
  { text: 'Nutrition', url: 'nutrition' },
  { text: 'Blog', url: 'blog' },
  { text: 'Testimonials', url: 'testimonials' },
  { text: 'Contact', url: 'contact-fitbodiz' }
];

const Header = () => {
  const [expandSideNav, setExpandSideNav] = useState(false);
  const toggleSideNav = () => {
    setExpandSideNav(expandSideNav === undefined ? true : !expandSideNav);
  };
  return (
    <header>
      <SideNav links={links} expanded={expandSideNav} onSelected={x => setExpandSideNav(false)} />
      <TopNav links={links} toggleSideNav={toggleSideNav} />
      {expandSideNav && <div id="sidenav-overlay" onClick={toggleSideNav} />}
    </header>
  );
};

export default Header;
