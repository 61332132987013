import './sideNav.css';
import React, { Fragment } from 'react';
import { FacebookLink } from '../../icons/facebook';
import { TwitterLink } from '../../icons/twitter';
import { Link } from 'gatsby';
import { Navbar } from 'react-bootstrap';

const SideNav = ({ links, expanded, onSelected }) => {
  if (expanded === undefined) {
    return null;
  }
  const slideClass = expanded ? 'slide-out' : 'slide-in';
  return (
    <Fragment>
      <ul id="slide-out" className={`side-nav sn-bg-1 custom-scrollbar ${slideClass}`}>
        <li>
          <div className="logo-wrapper waves-light">
            <Navbar.Brand href="/">
              <img src="/img/FitBodiz.png" className="img-fluid flex-center" />
            </Navbar.Brand>
          </div>
        </li>

        <li>
          <ul className="social">
            <li>
              <a href="https://www.facebook.com/fitbodizfitness" target="_blank" onClick={onSelected}>
                <FacebookLink />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/FitBodizFitness" target="_blank" onClick={onSelected}>
                <TwitterLink />
              </a>
            </li>
          </ul>
        </li>
        <li>
          <ul className="collapsible collapsible-accordion">
            {links.map((link, i) => (
              <li key={i} className="waves-effect">
                <Link key={i} to={link.url} className="waves-effect" onClick={onSelected}>
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </Fragment>
  );
};

export default SideNav;
