import './icons.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { IconProps } from './iconProps';
import { IconLink } from './IconLiink';

export const FacebookIcon = ({ color = '#3B5998' }: IconProps) => <FontAwesomeIcon icon={faFacebookF} color={color} />;

export const FacebookLink = () => (
  <a href="https://www.facebook.com/fitbodizfitness" target="_blank" className="nav-link" title="Facebook">
    <FacebookIcon />
  </a>
);

export const FacebookButton = () => (
  <IconLink
    color="white"
    background="btn-fb"
    icon={faFacebookF}
    href="https://www.facebook.com/fitbodizfitness"
    title="Facebook"
  />
);
