import React from 'react';
import { FacebookButton } from '../icons/facebook';
import { TwitterButton } from '../icons/twitter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { YoutubeButton } from '../icons/youtube';

export const Footer = () => (
  <footer className="page-footer primary-color center-on-small-only">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h5 className="title">Fit Bodiz</h5>
          <ul>
            <li>21 Topiary Gardens</li>
            <li>Garstang</li>
            <li>Lancashire</li>
            <li>PR3 1YF</li>
          </ul>
        </div>

        <div className="col-md-6">
          <h5 className="title">Contact</h5>
          <ul>
            <li>
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              Email{' '}
              <a href="mailto:info@fitbodiz.com" target="_blank">
                info@fitbodiz.com
              </a>
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              Email{' '}
              <a href="mailto:sarah@fitbodiz.com" target="_blank">
                sarah@fitbodiz.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="social-section">
      <ul>
        <li>
          <FacebookButton />
        </li>
        <li>
          <TwitterButton />
        </li>
        <li>
          <YoutubeButton />
        </li>
      </ul>
    </div>

    <div className="footer-copyright">
      <div className="container-fluid">
        © {new Date().getFullYear()} <a href="http://www.fitbodiz.com">Fit Bodiz - All Rights Reserved</a>
      </div>
    </div>
  </footer>
);
