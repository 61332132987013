import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { IconProps } from './iconProps';
import { IconLink } from './IconLiink';

export const TwitterIcon = ({ color = '#55ACEE' }: IconProps) => <FontAwesomeIcon icon={faTwitter} color={color} />;

export const TwitterLink = () => (
  <a href="https://twitter.com/FitBodizFitness" target="_blank" className="nav-link" title="Twitter">
    <TwitterIcon />
  </a>
);

export const TwitterButton = () => (
  <IconLink color="white" background="btn-tw" icon={faTwitter} href="https://twitter.com/FitBodizFitness" title="Twitter" />
);
