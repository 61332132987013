import './topNav.css';
import React from 'react';
import { Link } from 'gatsby';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { FacebookLink } from '../../icons/facebook';
import { TwitterLink } from '../../icons/twitter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { YoutubeLink } from '../../icons/youtube';

export const NavLink = ({ to, children }) => (
  <Link className="nav-link" to={to} activeClassName="active">
    {children}
  </Link>
);

const TopNav = ({ links, toggleSideNav }) => (
  <Navbar bg="white" variant="light" expand="lg" fixed="top" className="scrolling-navbar">
    <Container>
      <div className="hidden-lg-up d-flex align-self-center">
        <a className="button-collapse" onClick={toggleSideNav}>
          <FontAwesomeIcon icon={faBars} />
        </a>
      </div>

      <Navbar.Brand href="/">
        <img src="/img/FitBodiz.png" className="header-image" />
      </Navbar.Brand>
      <Nav className="hidden-md-down">
        {links.map((link, i) => (
          <NavLink key={i} to={link.url}>
            {link.text}
          </NavLink>
        ))}
      </Nav>
      <div className="d-flex ml-auto">
        <FacebookLink />
        <TwitterLink />
        <YoutubeLink />
      </div>
    </Container>
  </Navbar>
);

export default TopNav;
