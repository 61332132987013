import './icons.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps } from './iconProps';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconLinkProps extends IconProps {
  icon: IconProp;
  background?: string;
  href: string;
  title?: string;
}

export const IconLink = ({ color = '#3B5998', background = 'btn-primary', icon, href, title }: IconLinkProps) => (
  <a href={href} target="_blank" className={`btn-floating btn-small ${background}`} title={title}>
    <div className="icon-button-content">
      <FontAwesomeIcon icon={icon} color={color} />
    </div>
  </a>
);
