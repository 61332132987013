import './icons.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { IconProps } from './iconProps';
import { IconLink } from './IconLiink';

export const YoutubeIcon = ({ color = '#FF0000' }: IconProps) => <FontAwesomeIcon icon={faYoutube} color={color} />;

export const YoutubeLink = () => (
  <a href="https://www.youtube.com/channel/UCviMBl8-ye-aPXwiAHGM2lA" target="_blank" className="nav-link" title="YouTube">
    <YoutubeIcon />
  </a>
);

export const YoutubeButton = () => (
  <IconLink
    color="white"
    background="btn-fb"
    icon={faYoutube}
    href="https://www.youtube.com/channel/UCviMBl8-ye-aPXwiAHGM2lA"
    title="YouTube"
  />
);
